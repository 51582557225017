import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class AboutV5 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__about-us-area pb-115 go-top">
				<div className="container">
				<div className="row">
					<div className="col-lg-5 align-self-center">
					<div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
						<img src={publicUrl+"assets/img/others/About.jpg"} alt="Image" />
					</div>
					</div>
					<div className="col-lg-7 align-self-center">
					<div className="about-us-info-wrap">
						<div className="section-title-area ltn__section-title-2--- mb-20">
						<h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">About Us</h6>
						<h1 className="section-title">The Leading Real Estate
							Service Provider<span>.</span></h1>
						<p>Infinity Realty is your one-stop shop for all your property needs. We offer the best selection, service, and value in Dubai. Our team of experienced agents is dedicated to helping you find the perfect property for your needs. We understand that buying or selling a property can be a daunting task, but we are here to make the process as easy and stress-free as possible.</p>
						</div>
						{/* <div className="about-us-info-wrap-inner about-us-info-devide---">
						<p>We are a company that is built on trust and integrity. We believe that our clients deserve the best possible service, and we are committed to providing it. We are proud to be a part of the Dubai real estate market, and we look forward to helping you find your dream home.</p>
						</div> */}
						<div className="btn-wrapper animated">
						<Link to="/contact" className="theme-btn-1 btn btn-effect-1 text-uppercase">Contact Us</Link>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>	
        }
}

export default AboutV5