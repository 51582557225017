import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__testimonial-area ltn__testimonial-4 pt-115 pb-100 plr--9 go-top">
			  <div className="container-fluid">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Our Testimonial</h6>
			          <h1 className="section-title">Clients Feedback</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="ltn__testimonial-slider-4 ltn__testimonial-slider-4-active slick-arrow-1">
			          <div className="ltn__testimonial-item-5">
			            <div className="ltn__quote-icon">
			              <i className="far fa-comments" />
			            </div>
			            <div className="ltn__testimonial-image">
			              <img src={publicUrl+"assets/img/testimonial/jacob_william.jpg"} alt="quote" />
			            </div>
			            <div className="ltn__testimonial-info">
			              <p>Working with Infinity Realty was an exceptional experience. Their team of dedicated professionals guided us every step of the way, from finding our dream home to closing the deal. </p>
			              <h4>Jacob William</h4>
			              <h6>Senior IT Manager</h6>
			            </div>
			          </div>
			          <div className="ltn__testimonial-item-5">
			            <div className="ltn__quote-icon">
			              <i className="far fa-comments" />
			            </div>
			            <div className="ltn__testimonial-image"><img src={publicUrl+"assets/img/testimonial/ethan_james.jpg"} alt="quote" /></div>
			            <div className="ltn__testimonial-info">
			              <p>I cannot speak highly enough about the outstanding service provided by Infinity Realty. Their attention to detail, responsiveness, and market knowledge exceeded our expectations. They went above and beyond to make the selling process smooth and seamless. </p>
			              <h4>Ethan James</h4>
			              <h6>Architect</h6>
			            </div>
			          </div>
			          <div className="ltn__testimonial-item-5">
			            <div className="ltn__quote-icon">
			              <i className="far fa-comments" />
			            </div>
			            <div className="ltn__testimonial-image"><img src={publicUrl+"assets/img/testimonial/alex_hu.jpg"} alt="quote" /></div>
			            <div className="ltn__testimonial-info">
			              <p>I had the pleasure of working with Infinity Realty when I was searching for an investment property. Their team of experts demonstrated a deep understanding of the local market trends and helped me identify lucrative opportunities. Their commitment to personalized service and integrity is unmatched. </p>
			              <h4>Alex Hu</h4>
			              <h6>Professor</h6>
			            </div>
			          </div>
			          <div className="ltn__testimonial-item-5">
			            <div className="ltn__quote-icon">
			              <i className="far fa-comments" />
			            </div>
			            <div className="ltn__testimonial-image"><img src={publicUrl+"assets/img/testimonial/liam_mason.jpg"} alt="quote" /></div>
			            <div className="ltn__testimonial-info">
			              <p>Choosing Infinity Realty was the best decision we made when buying our first home. Their agents were incredibly patient, knowledgeable, and supportive throughout the entire process. They took the time to explain each step and address all our concerns, making us feel comfortable and confident in our choices. </p>
			              <h4>Liam Mason</h4>
			              <h6>Officer</h6>
			            </div>
			          </div>
			        </div>
			        <ul className="ltn__testimonial-quote-menu d-none d-lg-block">
			          <li><img src={publicUrl+"assets/img/testimonial/jacob_william.jpg"} alt="Quote image" /></li>
			          <li><img src={publicUrl+"assets/img/testimonial/ethan_james.jpg"} alt="Quote image" /></li>
			          <li><img src={publicUrl+"assets/img/testimonial/alex_hu.jpg"} alt="Quote image" /></li>
			          <li><img src={publicUrl+"assets/img/testimonial/liam_mason.jpg"} alt="Quote image" /></li>
			        </ul>
			      </div>
			    </div>
			  </div>
			</div>
        }
}

export default TestimonialV3