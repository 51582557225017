import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class Social extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__social-media">
			<ul>
				<li><a href="https://www.facebook.com/infinityrealtyHQ
" title="Facebook" target="_blank"><i className="fab fa-facebook-f" /></a></li>
				<li><a href="https://twitter.com/dubai_infinity_" title="Twitter" target="_blank"><i className="fab fa-twitter" /></a></li>
				<li><a href="https://www.instagram.com/infinity_realty_dxb/" title="Instagram" target="_blank"><i className="fab fa-instagram" /></a></li>
				<li><a href="https://www.linkedin.com/company/infinity-realty-dubai" title="Linkedin" target="_blank"><i className="fab fa-linkedin" /></a></li>
			</ul>
		</div>
        }
}

export default Social