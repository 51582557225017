import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ProductSliderV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="ltn__search-by-place-area section-bg-1 before-bg-top--- bg-image-top--- pt-115 pb-70" data-bs-bg={publicUrl+"assets/img/bg/20.jpg"}>
			  <div className="container">
			    <div className="row">
			      <div className="col-lg-12">
			        <div className="section-title-area ltn__section-title-2--- text-center">
			          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Area Properties</h6>
			          <h1 className="section-title">Properties By Location</h1>
			        </div>
			      </div>
			    </div>
			    <div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <img src={publicUrl+"assets/img/product-3/golf_grand_new.jpg"} alt="#" />
			            {/* <div className="search-by-place-badge">
			              <ul>
			                <li>2 Properties</li>
			              </ul>
			            </div> */}
			          </div>
			          <div className="search-by-place-info">
			            <h6>Dubai Hills Estate</h6>
			            <h4>Golf Grand</h4>
			            <div className="search-by-place-btn">
			              <Link to="/contact">Contact <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <img src={publicUrl+"assets/img/product-3/Garden_Homes_new.jpg"} alt="#" />
			            {/* <div className="search-by-place-badge">
			              <ul>
			                <li>5 Properties</li>
			              </ul>
			            </div> */}
			          </div>
			          <div className="search-by-place-info">
			            <h6>Palm Jumeirah</h6>
			            <h4>Garden Homes</h4>
			            <div className="search-by-place-btn">
			              <Link to="/contact">Contact <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			            <Link to="/product-details"><img src={publicUrl+"assets/img/product-3/mirador_new.jpg"} alt="#" /></Link>
			            {/* <div className="search-by-place-badge">
			              <ul>
			                <li>9 Properties</li>
			              </ul>
			            </div> */}
			          </div>
			          <div className="search-by-place-info">
			            <h6>Arabian Ranches</h6>
			            <h4>Mirador</h4>
			            <div className="search-by-place-btn">
			              <Link to="/contact">Contact <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4">
			        <div className="ltn__search-by-place-item">
			          <div className="search-by-place-img">
			          <img src={publicUrl+"assets/img/product-3/the_springs_7_new.jpg"} alt="#" />
			            {/* <div className="search-by-place-badge">
			              <ul>
			                <li>5 Properties</li>
			              </ul>
			            </div> */}
			          </div>
			          <div className="search-by-place-info">
			            <h6>The Springs</h6>
			            <h4>The Springs 7</h4>
			            <div className="search-by-place-btn">
			              <Link to="/contact">Contact <i className="flaticon-right-arrow" /></Link>
			            </div>
			          </div>
			        </div>
			      </div>
			      {/*  */}
			    </div>
			  </div>
			</div>
        }
}

export default ProductSliderV3